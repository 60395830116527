<template>
    <div class="publish">
        <div class="publish-header">
            <van-nav-bar title="发长文" left-text="取消" @click-left="$router.back(-1)">
                <template #right>
                    <van-button type="info" round size="small" @click="handlerpublish">发布</van-button>
                </template>
            </van-nav-bar>
        </div>
        <div class="editor-wrapper">
            <div class="editor-body">
                <van-field v-model="formData.title" rows="1" autosize type="textarea" placeholder="请输入标题" maxlength="100" />
                <div class="richtext-editor">
                    <Editor ref="tinymce" v-model="formData.content" :id="tinymceId" :init="init" :disabled="disabled"
                        placeholder="请输入正文" />
                </div>

            </div>
            <div class="editor-footer">
            </div>
        </div>
        <div class="messages">
            <MessagePoint mesageTxt="标题不能为空" :show="showTitle"></MessagePoint>
            <MessagePoint mesageTxt="内容不能为空" :show="showContent"></MessagePoint>
            <MessagePoint mesageTxt="发布成功" :show="publishSuccess"></MessagePoint>
        </div>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'//编辑器引入
import MessagePoint from '@/components/Mobile/MessagePoint'
export default {
    name: 'Publish',
    components: {
        Editor,
        MessagePoint,
    },
    data() {
        return {
            showTitle: false,
            showContent: false,
            publishSuccess: false,
            formData: {
                title: '',
                content: ''
            },
            tinymceId: '1',
            disabled: false,
            init: {
                selector: '#tinymce',
                language_url: './tinymce/langs/zh_CN.js',//汉化路径是自定义的，一般放在public或static里面
                language: 'zh_CN',
                skin_url: './tinymce/skins/ui/oxide',
                content_css: `./tinymce/skins/content/default/content.css`,
                plugins: [
                    'link', 'emoticons', 'preview',
                    // 'image',
                ],//插件
                //工具栏
                menubar: false, //隐藏菜单栏
                // image
                toolbar: 'undo redo|bold italic forecolor|link emoticons|preview', //字体大小
                height: 500,//高度
                placeholder: '在这里输入文字',
                branding: false,//隐藏右下角技术支持
                paste_preprocess: function (plugin, args) {
                    console.log(args.content);
                },
                paste_as_text: true,
                //init_instance_callback为回调配置项
                init_instance_callback: (editor) => {
                    // console.log(`回调----`)
                    editor.on('input', e => {
                        // console.log('文本框input触发')
                        this.$emit('input', e.target.innerHTML)
                    });
                    editor.on('change', e => {
                        // console.log('文本框change触发')
                        this.$emit('change', e.level.content)
                    })
                },
                images_upload_url: '',
                // image 图片上传自定义   目前转base64
                images_upload_handler: function (blobInfo, succFun, failFun) {
                    // console.log('images_upload_handler, blobInfo', blobInfo);
                    // console.log('base64', blobInfo.base64());
                    succFun('data:image/png;base64,' + blobInfo.base64());
                }
            }
        };
    },

    mounted() {

    },

    methods: {
        async handlerpublish() {
            // 标题为空
            if (!this.formData.title) {
                this.showTitle = true;
                setTimeout(() => {
                    this.showTitle = false;
                }, 500);
            }
            else {
                // 内容为空
                if (!this.formData.content) {
                    this.showContent = true;
                    setTimeout(() => {
                        this.showContent = false;
                    }, 500);
                }
                //  标题和内容不能为空
                else {
                    // 调发布长文接口
                    // 调用发布文章接口
                    try {
                        await this.api.
                            postFormAPISM(
                                {
                                    ...this.formData,
                                    is_draft: false,
                                    post_article_id: null,
                                },
                                "/article/save"
                            );
                        this.publishSuccess = true;
                        setTimeout(() => {
                            this.publishSuccess = false;
                            // 返回帖子
                        this.$router.back();
                        }, 1000);
                        //清除formData中的数据
                        this.formData = {
                            ...this.$options.data().formData,
                        }
                        
                    } catch (error) {
                        console.log(error);
                    }

                }
            }
        }

    }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>